<template>
  <div>
    <rotate-text>About Me</rotate-text>

    <div class="row justify-content-end">
      <div class="col-md-10">
        <div class="about-intro">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-5 col-10">
              <img src="/img/user-photo3.jpg" alt="Benjamin Iduwe" class="img-fluid" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-10">
              <div class="info-section">
                <h5 class="section-title">About Me</h5>
                <p class="about-info-text">
                  Hey there! I am Benjamin Iduwe, a Software Engineer who enjoys
                  turning complex problems into simple, beautiful, and intuitive
                  solutions.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-10">
              <div class="info-section">
                <h5 class="section-title mb-0" data-aos="fade-up">Expertise</h5>
                <div class="row no-gutters justify-content-between">
                  <div class="col-md-5">
                    <div class="expertise-item" data-aos="fade-up" data-aos-delay="100">
                      <h5>Engineering</h5>
                      <p>With a sound knowledge of the system development life cycle and algorithms, I will help automate or refactor your business process, with the appropriate design pattern and coding standard to build a robust, scalable, and test-driven web application.</p>
                    </div>
                    <!-- /expertise-item -->
                  </div>
                  <div class="col-md-5">
                    <div class="expertise-item" data-aos="fade-up" data-aos-delay="200">
                      <h5>Design</h5>
                      <p>I am just a typical web designer with sound knowledge of how to bring my idea into reality with the appropriate performant, eye-catching and responsive design to give your users the best user experience for your product.</p>
                    </div>
                    <!-- /expertise-item -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /col-md-10 -->
            <div class="col-md-10">
              <div class="info-section">
                <h5 class="section-title mb-0" data-aos="fade-up">Skills</h5>
                <div class="row no-gutters justify-content-between">
                  <div class="col-md-5">
                    <div class="experience-item" data-aos="fade-up" data-aos-delay="100">
                      <h5>Frontend Developement</h5>
                      <p>Html, Css, Sass, Bootstrap, Tailwind CSS,JQuery, VueJS and Javascript.</p>
                    </div>
                    <!-- /experience-item -->
                  </div>
                  <div class="col-md-5">
                    <div class="experience-item" data-aos="fade-up" data-aos-delay="100">
                      <h5>Backend Developement</h5>
                      <p>Laravel, PHP, NodeJS, ExpressJS, AdonisJs, NestJS, SQL, Neo4j, Postgres, MongoDB and REST API.</p> 
                    </div>
                    <!-- /experience-item -->
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-10">
              <div class="info-section">
                <h5 class="section-title mb-0" data-aos="fade-up">My Resume</h5>
                <div class="row no-gutters justify-content-between">
                  <div class="col-md-10">
                    <div class="expertise-item" data-aos="fade-up" data-aos-delay="200">
                      <p>
                        <a
                          href="./resume.pdf"
                          target="_blank"
                          class="theme-font-color font-weight-bold"
                        >Download Resume</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <social-icons />
        </div>
        <next-page path="/projects" title="Projects" content="Checkout My Projects"></next-page>
      </div>
    </div>
  </div>
</template>

<script>
import SocialIcons from "../components/SocialIcons";
export default {
  name: "About",
  components: {
    SocialIcons,
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "description",
          content: "Everything you need to know about Benjamin Iduwe",
        },
        {
          name: "og:title",
          content: "Everything you need to know about Benjamin Iduwe",
        },
      ],
    };
  },
  computed: {
    title() {
      return `About Me - ${this.$config.name}`;
    },
  },
};
</script>
